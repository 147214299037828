import demo from "./demo";
import address from "./address";
import header from "./header";
import footer from "./footer";
const zh_CN_lang = {
  header,
  address,
  demo,
  footer,
};
export default zh_CN_lang;
