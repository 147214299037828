"use client";

import i18next from "i18next";
import { useEffect, useState } from "react";
import { initReactI18next, useTranslation as useTranslationOrg } from "react-i18next";
// import resourcesToBackend from 'i18next-resources-to-backend'
// import LocizeBackend from 'i18next-locize-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { getOptions, languages, cookieName } from "./settings";
import cookieUtil from "@/utils/cookieUtils";

const runsOnServerSide = typeof window === "undefined";

if (typeof window !== "undefined" && !window.appI18n) {
  window.appI18n = i18next;
}

// on client side the normal singleton is ok
i18next
  .use(initReactI18next)
  // .use(LanguageDetector)
  // .use(resourcesToBackend((language, namespace) => import(`./locales/${language}/${namespace}.json`)))
  // .use(LocizeBackend) // locize backend could be used on client side, but prefer to keep it in sync with server side
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ["path", "htmlTag", "cookie", "navigator"],
    },
    preload: runsOnServerSide ? languages : [],
  });

export function useTranslation(lng, ns, options) {
  // const [cookies, setCookie] = useCookies([cookieName])
  if (!runsOnServerSide) {
    lng = cookieUtil.getCookie(cookieName);
  }
  const ret = useTranslationOrg(ns, options);
  const { i18n } = ret;
  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng);
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return;
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return;
      i18n.changeLanguage(lng);
    }, [lng, i18n]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // useEffect(() => {
    //   if (cookies.i18next === lng) return
    //   setCookie(cookieName, lng, { path: '/' })
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [lng, cookies.i18next])
  }
  return ret;
}
