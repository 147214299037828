/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Autocomplete, MenuItem, TextField, Checkbox, InputAdornment, IconButton, Paper, Select } from "@mui/material";
import React, { useState, useEffect } from "react";
import to from "await-to-js";
import { GroupedCounties, CountyInfo, Address, GoogleAddress } from "@/common-components-src/api/address/address.interface";
import { getCountyList, addAddress, queryPostCode, autocomplete, placeDetail, updateAddress } from "@/common-components-src/api/address/address.api";
import { curPostCodeOrAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import { setAddress } from "@/utils/addressUtils";
import AddressClear from "@/common-components-src/js/address/icons/AddressClear.svg";
import AddressSearch from "@/common-components-src/js/address/icons/AddressSearch.svg";
import UKToast from "@/common-components-src/components/UKToast/index"
import { getAddressStore } from "../AddressStore";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { MenuProps } from "@/common-components-src/js/newlogin/style/style";
import { getCountryListApi } from "@/common-components-src/api/login/index.api";
import PAGE_TYPE from "../const/pageType";
import { sendClickTrack, EXPOSURE_RECORD } from "@/tracks/25423";
import { createTrack } from "../utils/addressTrackUtils";
import { sendClickTrack as sendClickTrackSave, EXPOSURE_RECORD as SAVE_EXPOSURE_RECORD } from "@/tracks/25321";
import styles from "./index.module.scss";

interface DataItemProps {
    data?: Address | null;
    handleClose?: () => void;
    isModal?: boolean; //是否为弹窗
    isEdit?: boolean;
    isHiddenSetDefault?: boolean;// 是否需要展示设置默认项
    getAddressList?: () => void;
    getUpdateAddress?: (address: any) => void; //接口成功回调更新
    isHeaderModal?: boolean; //顶部地址创建入口
    isPersonModal?: boolean; //个人中心地址创建入口
    isAfterSales?: boolean; // 售后创建入口
    isCashierEntry?: boolean; //收银台入口
    getAddress?: (type: string) => void; //结算刷新地址
    pageType?: PAGE_TYPE;
}

const NewOrEditAddress: React.FC<DataItemProps> = ({ data, getAddressList,
    handleClose, isModal, isEdit, isHiddenSetDefault, isHeaderModal, getUpdateAddress,
    isAfterSales, isCashierEntry, getAddress, pageType }) => {
    const addressStore = getAddressStore();
    const [countys, setCountys] = useState<CountyInfo[] | null>(null);
    const [conty, setConty] = useState<CountyInfo | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // 防止多次点击
    const [googleAddress, setGoogleAddress] = useState<GoogleAddress[] | null>(null); // 谷歌api接口
    const [searchHintText, setSearchHintText] = useState("");
    const [open, setOpen] = useState(false); // 控制搜索下拉框的打开状态
    const [countryList, setCountryList] = useState<any>([]);
    const [idPrefix, setIdPrefix] = useState<any>(44); // 手机号国家标识

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        defAddress: false,
        addressDetail: "",
        addressLineTwo: "",
        postCode: "",
        countyName: "",
        countyId: "",
        urbanName: "",
        countryId: 300000,
        countryName: "United Kingdom",
        areaCode: "44",
        areaNameCode: "",
        countryCode: "",
    });
    const [errors, setErrors] = useState({
        fullNameError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        countyError: "",
        townCityError: "",
        postCodeError: "",
    });

    const countryCodes = [
        {
            value: "GB",
            label: "+44",
        },
    ];

    useEffect(() => {
        loadCounty();
    }, []);

    useEffect(() => {
        if (data) {
            // setFormData(data);
            setFormData(prevFormData => {
                const updatedData: any = {};
                Object.keys(prevFormData).forEach(key => {
                    if (key in data) {
                        const keyValue = data[key as keyof Address];
                        if (keyValue) {
                            updatedData[key] = keyValue;
                        }
                    }
                });
                return { ...prevFormData, ...updatedData }; // 合并之前的状态和更新后的字段
            });
            if (data?.countyName && data?.countyId) {
                setConty({ county: data?.countyName, id: data?.countyId });
            }
            if (data?.postCodeIsValid === 0) {
                setErrors({
                    ...errors,
                    postCodeError: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB"
                });
            }
            if (data?.addressDetailIsValid === 0) {
                setErrors({
                    ...errors,
                    addressLine1Error: "Address Line 1 should contain 5-35 letters,digits or spaces.",
                });
            }
            getCountryList(data);
        } else {
            getCountryList();
        }
    }, [data]);

    const getCountryList = async (parseData: any = null) => {
        const [err, res] = await getCountryListApi();
        if (res?.code === "200") {
            const data = res?.data?.internationalData
            setCountryList(data?.mobileLocations)
            if (parseData) {
                setIdPrefix(parseFloat(`+${parseData?.areaCode || 44}`))
                return;
            }
            const locationList = data?.defaultMobileLocations;
            if (locationList && locationList.length > 0) {
                setFormData({ ...formData, countryCode: locationList[0].name });
                setIdPrefix(parseFloat(`+${locationList[0].code}`));
            }
        }
    };

    const loadCounty = async () => {
        const [err, result] = await to(getCountyList());
        if (err) {
            setCountys(null);
        } else {
            const newData: CountyInfo[] = [];
            const groupedData = result.data as GroupedCounties[];
            groupedData?.forEach((group) => {
                group.countyInfoList.forEach((countyInfo) => {
                    if (countyInfo) {
                        newData.push(countyInfo);
                    }
                });
            });
            // 按照字母排序
            // newData.sort((a, b) => a.county.localeCompare(b.county));
            setCountys(newData);
        }
    };

    const requestPostCode = (postCode: string) => {
        if (!postCode) {
            return "";
        }
        return formData.postCode.replace(/\s+/g, ''); // 去掉所有空格
    };

    const requestAddAddress = async () => {
        const postCodeValue = requestPostCode(formData.postCode);
        // 先将formData中的postCode字段的值转为大写
        const updatedFormData = {
            ...formData,
            postCode: postCodeValue ? postCodeValue.toUpperCase() : postCodeValue,
        };
        const [err, result] = await to(
            isEdit
                ? data?.unSync === 1 ? addAddress({
                    ...updatedFormData,
                    addressId: data?.addressId,
                    source: 1, // 草稿（联合登录）地址去编辑保存
                }) : updateAddress({
                    ...updatedFormData,
                    addressId: data?.addressId,
                })
                : addAddress(updatedFormData),
        );
        if (result?.code === "200") {
            handleClickSave();
            setIsSubmitting(false);
            if (result?.msg) {
                UKToast.show({
                    content: result?.msg,
                    icon: "success",
                });
            }
            // 如果是发票、结算创建入口不需要存缓存数据
            if (isHiddenSetDefault || isAfterSales) {
                if (getUpdateAddress) {
                    getUpdateAddress(result?.data);
                }
                return;
            }
            setAddress(result?.data);
            const postCode = await curPostCodeOrAddress();
            const detailPostCode = await curPostCodeOrAddress(true);
            addressStore.setPostCode(postCode);
            addressStore.setDetailPostCode(detailPostCode);
            // 只有头部组件的添加才会刷新整个页面
            if (isHeaderModal) {
                location.reload();
            }
            if (getAddress) {
                getAddress("changeAddress");
            }
        } else {
            setIsSubmitting(false);
            if (result?.msg) {
                UKToast.show({
                    content: result?.msg,
                    icon: "error",
                });
            }
        }
    };

    const handleInputChange = async (event: any, value: any) => {
        console.log("event-----", event.target.value, value);
        // 执行了清除操作
        if (!value) {
            setConty(null);
            setFormData({ ...formData, countyName: "", countyId: "" });
            return;
        }
        if (value) {
            setConty({ county: value.county, id: value.id });
            setFormData({ ...formData, countyName: value.county, countyId: value.id });
        }
    };

    const handleSearchInputChange = async (event: any, value: any) => {
        console.log("event-----", event.target.value, value);
        if (!value) {
            setSearchHintText("");
            return;
        }
        if (value) {
            const [err, result] = await to(placeDetail({ placeId: value.placeId }));
            const data = result?.data;
            if (data) {
                const addressDetail = data.addressDetail?.length > 35 ? data.addressDetail.substring(0, 35) : data.addressDetail;
                const addressLineTwoBase = data.addressDetail?.length > 35 ? data.addressDetail.substring(35) : '';
                const addressLineTwo = addressLineTwoBase + (data.addressLineTwo ? `${data.addressLineTwo}` : '');
                // 如果 addressLineTwo 的长度大于 35，则截取前 35 个字符
                const finalAddressLineTwo = addressLineTwo.length > 35 ? addressLineTwo.substring(0, 35) : addressLineTwo;
                setFormData({
                    ...formData, postCode: data.postCode || '', countryName: data.countryName,
                    urbanName: data.urbanName || '', addressDetail: addressDetail, addressLineTwo: finalAddressLineTwo || '',
                    countyName: data.countyName, countyId: data.countyId,
                });
                setConty({ county: data?.countyName, id: data?.countyId });
            }
        }
    };

    const validateForm = () => {
        const newErrors = { ...errors };

        // Full Name
        const nameValue = formData.name ? formData.name.trim() : "";
        if (!nameValue || nameValue.length < 2 || nameValue.length > 200) {
            newErrors.fullNameError = !nameValue ? "Full name is required." :
                "Full Name should be 2-200 letters or spaces.";
        } else {
            const nameRegex = /^[a-zA-Z\s\-().'\/,.]+$/;
            if (!nameRegex.test(nameValue)) {
                newErrors.fullNameError = "Full Name should be 2-200 letters or spaces.";
            } else {
                newErrors.fullNameError = "";
            }
        }

        // Phone Number
        const mobileValue = formData.mobile ? formData.mobile.replace(/\s+/g, '') : "";
        if (!mobileValue || mobileValue.length < 7 || mobileValue.length > 15) {
            newErrors.phoneNumberError = !mobileValue ? "Phone number is required." :
                "Phone number should be a number of 7-15 digits.";
        } else {
            const phoneRegex = /^[0-9+ ]+$/;
            if (!phoneRegex.test(mobileValue)) {
                newErrors.phoneNumberError = "Phone number should be a number of 7-15 digits.";
            } else {
                newErrors.phoneNumberError = "";
            }
        }

        // Address Line 1
        const addressDetailValue = formData.addressDetail ? formData.addressDetail.trim() : "";
        if (!addressDetailValue || addressDetailValue.length < 5 || addressDetailValue.length > 35) {
            newErrors.addressLine1Error = !addressDetailValue ? "Please enter a street address." :
                "Address Line 1 should contain 5-35 letters,digits or spaces.";
        } else {
            const addressRegex = /^[a-zA-Z0-9\s,\\\-()\/'&"#.“”‘’]+$/;
            if (!addressRegex.test(addressDetailValue)) {
                newErrors.addressLine1Error = "Address Line 1 should contain 5-35 letters,digits or spaces.";
            } else {
                newErrors.addressLine1Error = "";
            }
        }

        // Address Line 2
        const addressLineTwoValue = formData.addressLineTwo ? formData.addressLineTwo.trim() : "";
        if (
            addressLineTwoValue && (addressLineTwoValue.length > 35)
        ) {
            newErrors.addressLine2Error = "Address Line 2 should contain no more than 35 letters,digits or spaces.";
        } else {
            const addressLine2Regex = /^[a-zA-Z0-9\s,\\\-()\/'&"#.“”‘’]+$/;
            if (addressLineTwoValue && !addressLine2Regex.test(addressLineTwoValue)) {
                newErrors.addressLine2Error = "Address Line 2 should contain no more than 35 letters,digits or spaces.";
            } else {
                newErrors.addressLine2Error = "";
            }
        }

        // County
        // const countyNameValue = formData.countyName.trim();
        // if (!countyNameValue) {
        //     newErrors.countyError = "Please select your county.";
        // } else if (countyNameValue.length < 2 || countyNameValue.length > 50) {
        //     newErrors.countyError = "Please select a county.";
        // } else {
        //     const countyRegex = /^[a-zA-Z\s]+$/;
        //     if (!countyRegex.test(countyNameValue)) {
        //         newErrors.countyError = "Please select a county.";
        //     } else {
        //         newErrors.countyError = "";
        //     }
        // }

        // Town/City
        const urbanNameValue = formData.urbanName ? formData.urbanName.trim() : "";
        if (!urbanNameValue || urbanNameValue.length < 2 || urbanNameValue.length > 50) {
            newErrors.townCityError = !urbanNameValue ? "Please enter your town/city." : "Town/City should contain 2-50 characters.";
        } else {
            const townCityRegex = /^[a-zA-Z\s,.-]+$/;
            if (!townCityRegex.test(urbanNameValue)) {
                newErrors.townCityError = "Town/City should contain 2-50 characters.";
            } else {
                newErrors.townCityError = "";
            }
        }

        // Postcode
        const postCodeValue = formData.postCode ? formData.postCode.replace(/\s+/g, '') : ""; // 去掉所有空格
        if (!postCodeValue || postCodeValue.length < 3 || postCodeValue.length > 20) {
            newErrors.postCodeError = !postCodeValue ? "Please enter your postcode." : "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB";
        } else {
            // const postCodeRegex = /^[a-zA-Z0-9\s]+$/;
            const postCodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
            if (!postCodeRegex.test(postCodeValue)) {
                newErrors.postCodeError = "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB";
            } else {
                newErrors.postCodeError = "";
            }
        }

        setErrors(newErrors);
        console.log("111", Object.keys(newErrors).length, newErrors);
        const hasErrors = Object.values(newErrors).some((error) => error !== "");
        return !hasErrors;
    };

    const handleClickSave = () => {
        if (handleClose) {
            handleClose();
        }
        // 如果顶部创建入口不需要刷新列表直接关闭
        if (isHeaderModal || isAfterSales || isCashierEntry) {
            return;
        }
        if (getAddressList) {
            getAddressList();
        }
    };

    const handleSubmit = () => {
        console.log("formData", formData);
        const isValid = validateForm();
        if (isValid) {
            if (isSubmitting) {
                return;
            }
            setIsSubmitting(true);
            // 收银台支付不需要调接口，直接返回数据
            if (isCashierEntry) {
                if (getUpdateAddress) {
                    getUpdateAddress({ ...formData });
                }
                setIsSubmitting(false);
                handleClickSave();
                return;
            }
            requestAddAddress();
        }
    };

    const handleNameBlur = () => {
        if (!formData || !formData.name) {
            return;
        }
        const nameValue = formData.name.trim();
        if (!nameValue || nameValue.length < 2 || nameValue.length > 200) {
            setErrors({
                ...errors,
                fullNameError: !nameValue ? "Full name is required." : "Full Name should be 2-200 letters or spaces.",
            });
        } else {
            // const nameRegex = /^[a-zA-Z\s-]+$/;
            const nameRegex = /^[a-zA-Z\s\-().'\/,.]+$/;
            if (!nameRegex.test(nameValue)) {
                setErrors({ ...errors, fullNameError: "Full Name should be 2-200 letters or spaces." });
            } else {
                setErrors({ ...errors, fullNameError: "" });
            }
        }
    };

    const handleMobileBlur = () => {
        if (!formData || !formData.mobile) {
            return;
        }
        const mobileValue = formData.mobile.replace(/\s+/g, '');
        if (!mobileValue || mobileValue.length < 7 || mobileValue.length > 15) {
            setErrors({
                ...errors,
                phoneNumberError: !mobileValue ? "Phone number is required." : "Phone number should be a number of 7-15 digits.",
            });
        } else {
            const phoneRegex = /^[0-9+ ]+$/;
            if (!phoneRegex.test(mobileValue)) {
                setErrors({ ...errors, phoneNumberError: "Phone number should be a number of 7-15 digits." });
            } else {
                setErrors({ ...errors, phoneNumberError: "" });
            }
        }
    };

    const handleAddressLine1Blur = () => {
        if (!formData || !formData.addressDetail) {
            return;
        }
        const addressDetailValue = formData.addressDetail.trim();
        if (!addressDetailValue || addressDetailValue.length < 5 || addressDetailValue.length > 35) {
            setErrors({
                ...errors,
                addressLine1Error: !addressDetailValue ? "Please enter a street address." :
                    "Address Line 1 should contain 5-35 letters,digits or spaces.",
            });
        } else {
            const addressRegex = /^[a-zA-Z0-9\s,\\\-()\/'&"#.“”‘’]+$/;
            if (!addressRegex.test(addressDetailValue)) {
                setErrors({
                    ...errors,
                    addressLine1Error: "Address Line 1 should contain 5-35 letters,digits or spaces.",
                });
            } else {
                setErrors({
                    ...errors,
                    addressLine1Error: "",
                });
            }
        }
    };

    const handleAddressLine2Blur = () => {
        if (!formData || !formData.addressLineTwo) {
            return;
        }
        const addressLineTwoValue = formData.addressLineTwo ? formData.addressLineTwo.trim() : "";
        if (
            addressLineTwoValue &&
            (addressLineTwoValue.length > 35)
        ) {
            setErrors({
                ...errors,
                addressLine2Error: "Address Line 2 should contain no more than 35 letters,digits or spaces.",
            });
        } else {
            const addressLine2Regex = /^[a-zA-Z0-9\s,\\\-()\/'&"#.“”‘’]+$/;
            if (addressLineTwoValue && !addressLine2Regex.test(addressLineTwoValue)) {
                setErrors({
                    ...errors,
                    addressLine2Error: "Address Line 2 should contain no more than 35 letters,digits or spaces.",
                });
            } else {
                setErrors({
                    ...errors,
                    addressLine2Error: "",
                });
            }
        }
    };

    const handleContyBlur = () => {
        if (!formData || !formData.countyName) {
            return;
        }
        const countyNameValue = formData.countyName.trim();
        if (!countyNameValue) {
            setErrors({ ...errors, countyError: "Please select your county." });
        } else if (countyNameValue.length < 2 || countyNameValue.length > 50) {
            setErrors({ ...errors, countyError: "Please select a county." });
        } else {
            const countyRegex = /^[a-zA-Z\s]+$/;
            if (!countyRegex.test(countyNameValue)) {
                setErrors({ ...errors, countyError: "Please select a county." });
            } else {
                setErrors({ ...errors, countyError: "" });
            }
        }
    };

    const handleTownBlur = () => {
        if (!formData || !formData.urbanName) {
            return;
        }
        const urbanNameValue = formData.urbanName.trim();
        if (!urbanNameValue || urbanNameValue.length < 2 || urbanNameValue.length > 50) {
            setErrors({ ...errors, townCityError: !urbanNameValue ? "Please enter your town/city." : "Town/City should contain 2-50 characters." });
        } else {
            const townCityRegex = /^[a-zA-Z\s,.-]+$/;
            if (!townCityRegex.test(urbanNameValue)) {
                setErrors({ ...errors, townCityError: "Town/City should contain 2-50 characters." });
            } else {
                setErrors({ ...errors, townCityError: "" });
            }
        }
    };

    const handlePostCodeBlur = async () => {
        if (!formData || !formData.postCode) {
            setErrors({
                ...errors,
                postCodeError: "Please enter your postcode."
            });
            return;
        }
        const postCodeValue = formData.postCode.replace(/\s+/g, ''); // 去掉所有空格
        const postCodeRegex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
        if (!postCodeRegex.test(postCodeValue)) {
            setErrors({
                ...errors,
                postCodeError: "Please enter a valid postcode, such as: SW1A 1AA, EC1A 1BB"
            });
            return;
        }
        setErrors({
            ...errors,
            postCodeError: ""
        });
        const [err, result] = await to(
            queryPostCode({ postCode: postCodeValue ? postCodeValue.toUpperCase() : postCodeValue }),
        );
        if (result?.code === "200" && result?.data) {
            setFormData({ ...formData, postCode: result?.data?.postCode });
            setErrors({ ...errors, postCodeError: "" });
        } else {
            if (result?.msg) {
                setErrors({ ...errors, postCodeError: result?.msg });
            }
        }
    };

    const handleSearchAddress = async (searchText: string) => {
        console.log("searchText 12121", searchText);
        if (!searchText) {
            setGoogleAddress(null);
            return;
        }
        const [err, result] = await to(autocomplete({ searchText }));
        if (err) {
            setGoogleAddress(null);
        } else {
            const list = result?.data;
            if (list) {
                setGoogleAddress(list as GoogleAddress[]);
            }
        }
    };

    return (
        <div
            className={styles.container}
            exptag={"exp|" + EXPOSURE_RECORD.NeworderAddressNewAdd_Expo}
            data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
            data-exptag-json-param={JSON.stringify(createTrack(pageType || "", isEdit || false))}
        >
            <div className={styles.title}>{isEdit ? "Edit address" : "Add a new address"}</div>
            <div className={styles.countryRegion}>Country / Region<span style={{ color: "#CC0C1C" }}>*</span></div>
            <TextField
                slotProps={{
                    input: {
                        readOnly: true,
                        startAdornment: (
                            <img
                                width={18}
                                height={12}
                                style={{ marginRight: "4px" }}
                                src="https://st.joy-sourcing.com/website/other/avatar.png"
                            />
                        ),
                    },
                }}
                sx={{ width: 412 }}
                id="outlined-disabled"
                // label="Country / Region"
                size="small"
                onClick={() => {
                    sendClickTrack.NeworderAddressNewAdd_Region(createTrack(pageType || "", isEdit || false));
                }}
                // onChange={handleChange}
                value={formData.countryName}
                exptag={"exp|" + EXPOSURE_RECORD.NeworderAddressNewAdd_Region_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                data-exptag-json-param={JSON.stringify(createTrack(pageType || "", isEdit || false))}
            />
            <div className={styles.countryRegion}>Contact Information<span style={{ color: "#CC0C1C" }}>*</span></div>
            <div style={{ display: "flex" }}>
                <TextField
                    required
                    error={!!errors.fullNameError}
                    helperText={errors.fullNameError}
                    id="outlined-required"
                    // label="First name and last name or company name"
                    size="small"
                    placeholder="First name and last name or company name"
                    onBlur={handleNameBlur}
                    value={formData.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, name: event.target.value });
                    }}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {formData.name && (
                                        <IconButton onClick={() => {
                                            setFormData({ ...formData, name: "" });
                                        }}>
                                            <AddressClear />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        },
                    }}
                    sx={{ width: 412 }}
                />
                <Select
                    autoWidth
                    value={idPrefix}
                    // variant="standard"
                    onChange={(event: any) => {
                        const value = event.target.value;
                        setIdPrefix(Number(value));
                        const item = countryList?.find((opt: any) => opt.code === `+${value}`);
                        setFormData({ ...formData, areaCode: value, areaNameCode:  item?.name, countryCode: item?.name });
                    }}
                    MenuProps={MenuProps as any}
                    sx={{
                        width: 150,
                        height: 40,
                        marginLeft: "24px",
                        "& .MuiInputBase-input:focus": {
                            backgroundColor: "transparent",
                        }
                    }}
                    renderValue={(value) => {
                        const item = countryList?.find((opt: any) => opt.code === `+${value}`);
                        return item ? item?.name + ` (${item?.code})` : `GB (+44)`;
                    }}
                >
                    {countryList && countryList?.length > 0 ? countryList.map((item: any, index: number) => {
                        return <MenuItem key={index} value={parseFloat(item.code)} >{item.country}{" "}{item.code}</MenuItem>
                    }) : <MenuItem value={44}>GB(+44)</MenuItem>
                    }
                </Select>
                {/* <TextField
                    id="outlined-select-currency"
                    select
                    sx={{ width: 100, marginLeft: "24px" }}
                    // label="Country code"
                    size="small"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        // setFormData({ ...formData, countryCode: event.target.value });
                        const value = event.target.value;
                        console.log("value111", value);
                        setIdPrefix(Number(value));
                        const item = countryList?.find((opt: any) => opt.code === `+${value}`);
                        setFormData({ ...formData, areaCode: value, countryCode: item?.name });
                    }}
                    value={idPrefix}
                >
                    {countryList && countryList?.length > 0 ? countryList.map((item: any, index: number) => {
                        return <MenuItem key={index} value={parseFloat(item.code)} >{item.country}{" "}{item.code}</MenuItem>
                    }) : <MenuItem value={44}>GB(+44)</MenuItem>
                    }
                </TextField> */}
                <TextField
                    id="outlined-basic"
                    // label="Phone number"
                    placeholder="Phone number"
                    variant="outlined"
                    size="small"
                    onBlur={handleMobileBlur}
                    error={!!errors.phoneNumberError}
                    helperText={errors.phoneNumberError}
                    value={formData.mobile}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, mobile: event.target.value });
                    }}
                    slotProps={{
                        input: {
                            endAdornment: (
                                <InputAdornment position="end">
                                    {formData.mobile && (
                                        <IconButton onClick={() => {
                                            setFormData({ ...formData, mobile: "" });
                                        }}>
                                            <AddressClear />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        },
                    }}
                    required
                    sx={{ width: 254, marginLeft: "8px" }}
                />
            </div>

            <div className={styles.countryRegion}>Address</div>
            <Autocomplete
                disablePortal
                options={googleAddress ? googleAddress : []}
                // value={{formattedAddress: searchHintText, placeId: ""}}
                getOptionLabel={(option) => option.formattedAddress}
                size="small"
                sx={{ width: 412 }}
                inputValue={searchHintText}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                open={open} // 控制下拉框的打开状态
                onChange={(event, value) => handleSearchInputChange(event, value)}
                onFocus={() => {
                    sendClickTrack.NeworderAddressNewAdd_Search(createTrack(pageType || "", isEdit || false));
                }}
                exptag={"exp|" + EXPOSURE_RECORD.NeworderAddressNewAdd_Search_Expo}
                data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                data-exptag-json-param={JSON.stringify(createTrack(pageType || "", isEdit || false))}
                renderInput={(params) =>
                    <div style={{ position: 'relative' }}>
                        <AddressSearch className={styles.searchIcon} />
                        {/* {!searchHintText && <span className={styles.searchText}>Postcode,  street, or address</span>} */}
                        <TextField
                            {...params}
                            placeholder="Postcode,  street, or address"
                            // label="Postcode,  street, or address"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchHintText(event.target.value);
                                handleSearchAddress(event.target.value);
                            }}
                            slotProps={{
                                input: {
                                    ...params.InputProps, // 确保保留原有的输入属性
                                    style: { paddingLeft: 30 }, // 为图标留出空间

                                },
                            }}
                        />
                    </div>
                }
                clearIcon={
                    <AddressClear style={{ cursor: 'pointer' }} />
                }
                ListboxComponent={(props) => (
                    <Paper {...props} style={{ maxHeight: 300, overflow: 'auto' }}>
                        {props.children}
                        <div className={styles.searchBottomItem}>
                            Can’t find the address？{" "}<span className={styles.searchBottomItemEntry}
                                onClick={(event) => {
                                    // 关闭下拉框
                                    setOpen(false);
                                }}> Enter it manually</span>
                        </div>
                    </Paper>
                )}
                renderOption={(props, option, { inputValue, index }) => {
                    const { key, ...optionProps } = props;
                    const matches = match(option.formattedAddress, inputValue, { insideWords: true });
                    const parts = parse(option.formattedAddress, matches);
                    return (
                        <li key={key} {...optionProps}
                            style={{ cursor: 'pointer', padding: '8px 12px' }} // 添加鼠标指针和内边距
                            onMouseEnter={(e) => {
                                e.currentTarget.style.backgroundColor = '#f0f0f0'; // 鼠标悬停背景色
                            }}
                            onMouseLeave={(e) => {
                                e.currentTarget.style.backgroundColor = 'transparent'; // 鼠标离开时恢复背景色
                            }}
                        >
                            <div>
                                {parts.map((part: any, idx: number) => (
                                    <span
                                        key={idx}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
            <div className={styles.countryRegion} style={{ color: "#505259" }}>Postcode<span style={{ color: "#CC0C1C" }}>*</span></div>
            <TextField
                required
                id="outlined-required"
                // label="Such as: SW1A 1AA, EC1A 1BB"
                placeholder="Such as: SW1A 1AA, EC1A 1BB"
                size="small"
                onBlur={handlePostCodeBlur}
                error={!!errors.postCodeError}
                helperText={errors.postCodeError}
                value={formData.postCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFormData({ ...formData, postCode: event.target.value });
                }}
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                {formData.postCode && (
                                    <IconButton onClick={() => {
                                        setFormData({ ...formData, postCode: "" });
                                    }}>
                                        <AddressClear />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        ),
                    },
                    htmlInput: {
                        maxLength: 20,
                        minLength: 3,
                    }
                }}
                sx={{ width: 412 }}
            />
            <div className={styles.flexTop}>
                <div>
                    <div className={styles.countryRegion} style={{ color: "#505259" }}>Address Line 1<span style={{ color: "#CC0C1C" }}>*</span></div>
                    <TextField
                        required
                        id="outlined-required"
                        // label="Street, address, company name, C/O"
                        placeholder="House number,building,street"
                        size="small"
                        onBlur={handleAddressLine1Blur}
                        error={!!errors.addressLine1Error}
                        helperText={errors.addressLine1Error}
                        value={formData.addressDetail}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, addressDetail: event.target.value });
                        }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formData.addressDetail && (
                                            <IconButton onClick={() => {
                                                setFormData({ ...formData, addressDetail: "" });
                                            }}>
                                                <AddressClear />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            },
                        }}
                        sx={{ width: 412 }}
                    />
                </div>
                <div style={{ marginLeft: "24px" }}>
                    <div className={styles.countryRegion} style={{ color: "#505259" }}>Address Line 2</div>
                    <TextField
                        id="outlined-required"
                        // label="Apartment, suite, unit, building, floor, etc. (optional)"
                        placeholder={formData.addressDetail ? "" : "Apartment,suit,unit,floor,ect.(optional)"}
                        size="small"
                        onBlur={handleAddressLine2Blur}
                        value={formData.addressLineTwo}
                        error={!!errors.addressLine2Error}
                        helperText={errors.addressLine2Error}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, addressLineTwo: event.target.value });
                        }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formData.addressLineTwo && (
                                            <IconButton onClick={() => {
                                                setFormData({ ...formData, addressLineTwo: "" });
                                            }}>
                                                <AddressClear />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            },
                        }}
                        sx={{ width: 412 }}
                    />
                </div>
            </div>
            <div className={styles.flexTop}>
                <div>
                    <div className={styles.countryRegion} style={{ color: "#505259" }}>County</div>
                    <Autocomplete
                        disablePortal
                        options={countys ? countys : []}
                        getOptionLabel={(option) => option.county}
                        size="small"
                        sx={{ width: 412 }}
                        value={conty}
                        // onBlur={handleContyBlur}
                        onChange={(event, value) => handleInputChange(event, value)}
                        renderInput={(params) => (
                            <div style={{ position: 'relative' }}>
                                <AddressSearch className={styles.searchIcon} style={{ top: errors.countyError ? "30%" : "50%" }} />
                                {/* {countyHint && <span className={styles.searchText} style={{ top: errors.countyError ? "30%" : "50%" }}>Select County</span>} */}
                                <TextField
                                    {...params}
                                    // label="County"
                                    // required
                                    placeholder="Select County"
                                    error={!!errors.countyError}
                                    helperText={errors.countyError}
                                    slotProps={{
                                        input: {
                                            ...params.InputProps, // 确保保留原有的输入属性
                                            style: { paddingLeft: 30 }, // 为图标留出空间

                                        },
                                    }}
                                />
                            </div>
                        )}
                        clearIcon={
                            <AddressClear style={{ cursor: 'pointer' }} />
                        }
                    />
                </div>
                <div style={{ marginLeft: "24px" }}>
                    <div className={styles.countryRegion} style={{ color: "#505259" }}>Town/City<span style={{ color: "#CC0C1C" }}>*</span></div>
                    <TextField
                        required
                        id="outlined-required"
                        // label="Enter town/city"
                        placeholder="Enter Town/City"
                        size="small"
                        onBlur={handleTownBlur}
                        value={formData.urbanName}
                        error={!!errors.townCityError}
                        helperText={errors.townCityError}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, urbanName: event.target.value });
                        }}
                        slotProps={{
                            input: {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {formData.urbanName && (
                                            <IconButton onClick={() => {
                                                setFormData({ ...formData, urbanName: "" });
                                            }}>
                                                <AddressClear />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            },
                        }}
                        sx={{ width: 412 }}
                    />
                </div>
            </div>
            
            {!isHiddenSetDefault &&
                <div className={styles.flex9}
                    exptag={"exp|" + EXPOSURE_RECORD.NeworderAddressNewAdd_Default_Expo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                    data-exptag-json-param={JSON.stringify({ ...createTrack(pageType || "", isEdit || false), isdefaultaddr: formData?.defAddress ? "1" : "0" })}
                >
                    <Checkbox
                        sx={{
                            color: "#E0E1E5",
                            padding: "0px",
                            marginLeft: "-2px",
                            "&.Mui-checked": {
                                color: "#CC0C1C",
                                // padding: "0px",
                            },
                        }}
                        checked={formData.defAddress}
                        onClick={(event) => {
                            const target = event.target as HTMLInputElement;
                            setFormData({ ...formData, defAddress: target.checked });
                            sendClickTrack.NeworderAddressNewAdd_Default({ ...createTrack(pageType || "", isEdit || false), chstatus: target.checked ? "1" : "0" });
                        }}
                    />
                    <div className={styles.setText}>Set as default shipping address</div>
                </div>}
            {!isModal && (
                <Button
                    className={styles.buttonCheck}
                    style={{ opacity: isSubmitting ? 0.4 : 1 }}
                    exptag={"exp|" + SAVE_EXPOSURE_RECORD.NeworderReceiverList_NewAddress_Expo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                    data-exptag-json-param={JSON.stringify({ from_page: "2" })}
                    onClick={() => {
                        handleSubmit();
                        sendClickTrackSave.NeworderReceiverList_NewAddress({ from_page: "2" });
                    }}
                >
                    Save
                </Button>
            )}
            {isModal && (
                <div
                    className={styles.bottomFloat}
                    exptag={"exp|" + EXPOSURE_RECORD.NeworderAddressNewAdd_Save_Expo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0.3, "repeated":false, "area_rate": 0.01}'
                    data-exptag-json-param={JSON.stringify(createTrack(pageType || "", isEdit || false))}
                >
                    <Button
                        className={styles.closeButton}
                        style={{ opacity: isSubmitting ? 0.4 : 1 }}
                        onClick={() => {
                            handleSubmit();
                            sendClickTrack.NeworderAddressNewAdd_Save(createTrack(pageType || "", isEdit || false));
                        }}
                    >
                        Save
                    </Button>
                </div>
            )}
        </div>
    );
};

export default NewOrEditAddress;
