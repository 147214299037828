// 多语言国家配置
export interface LANGUAGE_PROPS {
  type: number;
  name: string;
  displayName: string;
  languageCode: string;
}

export enum LANGUAGE_ENUM {
  English = 1,
  Chinese = 2,
  Nederlands = 3,
  Deutsch = 4,
  Francais = 5,
}

export const LANGUAGE_CONFIG: LANGUAGE_PROPS[] = [
  { type: LANGUAGE_ENUM.English, name: "English - EN", displayName: "EN", languageCode: "en_GB" },
  { type: LANGUAGE_ENUM.Chinese, name: "中文(简体) - ZH", displayName: "ZH", languageCode: "zh_CN" },
  // { type: LANGUAGE_ENUM.Nederlands, name: "Nederlands - NL", simpleName: "NL" },
  // { type: LANGUAGE_ENUM.Deutsch, name: "Deutsch - DE", simpleName: "DE" },
  // { type: LANGUAGE_ENUM.Francais, name: "Francais - FR", simpleName: "FR" },
];
