'use client';
import React from "react";
import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import styles from "./index.module.scss";

interface CounterProps {
  value: number;
  minAmount: number;
  maxAmount: number;
  stock?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  immediately?: boolean;
  size?: string; // 目前暂时支持small、middle(购物车列表可见)
  isSetDefaultValue?: boolean;
  setIsCancelDelete?: (val: boolean) => void;
  onCounterType?: (counterType: string) => void; // 计数器类型 + - input，可用于埋点
  isShowDeleteIcon?: boolean; // 315购物车需求新增，支持展示删除图标
}

interface MyState {
  value: number;
  reduceDisable: boolean;
  addDisable: boolean;
}

export enum CounterType {
  reduce = "reduce",
  add = "add",
  input = "input",
}

export const debounce = <T extends (...args: unknown[]) => void>(func: T, wait: number, immediate?: boolean) => {
  let timeout: NodeJS.Timeout | null = null;

  return function (this: unknown, ...args: Parameters<T>) {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      timeout = null;
      if (!immediate) {
        func.apply(this, args);
      }
    }, wait);

    if (immediate && !timeout) {
      func.apply(this, args);
    }
  };
};

const UKCounter: React.FC<CounterProps> = observer(
  ({
    value,
    minAmount,
    maxAmount,
    stock,
    disabled = false,
    onChange,
    immediately = true,
    size,
    isSetDefaultValue,
    setIsCancelDelete,
    onCounterType,
    isShowDeleteIcon,
  }) => {
    const [state, setState] = useState<MyState>({
      value,
      reduceDisable: false,
      addDisable: false,
    });
    const [isInput, setInput] = useState(false); // 默认没获取焦点

    useEffect(() => {
      if (value) {
        setState((prevState) => ({
          ...prevState,
          value: value,
        }));
      }
      if (isSetDefaultValue) {
        setState((prevState) => ({
          ...prevState,
          value: 1,
        }));
        if (setIsCancelDelete) {
          setIsCancelDelete(false);
        }
      }
    }, [value, isSetDefaultValue]); // 依赖于 state.value

    const maxLocal = stock ? Math.min(stock, maxAmount) : maxAmount;

    useEffect(() => {
      // if (state.value!== value) {
      //   computeCounter();
      // }
      if (!isInput) {
        computeCounter();
      }
    }, [state.value, isInput]); // 依赖于 state.value

    const computeCounter = () => {
      let localValue = state.value;
      if (state.value >= maxLocal) {
        setState((prevState) => ({
          ...prevState,
          addDisable: true,
        }));
        localValue = maxLocal;
        setState((prevState) => ({
          ...prevState,
          value: localValue,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          addDisable: false,
        }));
      }
      if (state.value <= minAmount) {
        setState((prevState) => ({
          ...prevState,
          reduceDisable: true,
        }));
        localValue = minAmount;
        setState((prevState) => ({
          ...prevState,
          value: localValue,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          reduceDisable: false,
        }));
      }
      if (state.value !== value) {
        onChange(state.value);
      }
      // onChange(state.value);
    };

    const update = debounce(() => {
      computeCounter();
    }, 500);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInput(true);
      const val = parseInt(e.target.value);
      // if (isNaN(val)) {
      //    val = minAmount;
      // }
      setState((prevState) => ({
        ...prevState,
        value: val,
      }));
      onCounterType && onCounterType(CounterType.input);
      // update();
    };

    const handleReduce = () => {
      setInput(false);
      if (state.reduceDisable || disabled) {
        return;
      }
      setState((prevState) => ({
        ...prevState,
        value: prevState.value - 1,
      }));
      onCounterType && onCounterType(CounterType.reduce);
    };

    const handleAdd = () => {
      setInput(false);
      if (state.addDisable || disabled) {
        return;
      }
      setState((prevState) => ({
        ...prevState,
        value: prevState.value + 1,
      }));
      onCounterType && onCounterType(CounterType.add);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setInput(false);
      const val = e.target.value;
      setState((prevState) => ({
        ...prevState,
        value: Number(val),
      }));
      // if (!val) {
      //   // setState((prevState) => ({
      //   //  ...prevState,
      //   //   value: 1,
      //   // }));
      //   // onChange(state.value);
      //   setState((prevState) => ({
      //     ...prevState,
      //     value: minAmount, // 设置为最小值
      //   }));
      //   // onChange(minAmount); // 直接传递最小值
      // }
    };

    return (
      <div className={`${styles.counter} ${styles["counter-row"]} ${size ? styles[`${size}-counter`] : ""}`}>
        <i
          className={`${styles["counter-input-icon"]} ${styles["counter-reduce"]} 
          ${
            state.reduceDisable || disabled
              ? styles["counter-reduce-disable"]
              : isShowDeleteIcon && state.value === 1
              ? styles["counter-reduce-middle-delete"]
              : styles[`counter-reduce-${size ? size + "-" : ""}enable`]
          }`}
          onClick={debounce(handleReduce, 300)}
        ></i>
        <input type="number" value={state.value} onBlur={handleBlur} disabled={disabled} onChange={handleChange} />
        <i
          className={`${styles["counter-input-icon"]} ${styles["counter-add"]} ${
            state.addDisable || disabled ? styles["counter-add-disable"] : styles[`counter-add-${size ? size + "-" : ""}enable`]
          }`}
          onClick={debounce(handleAdd, 300)}
        ></i>
      </div>
    );
  },
);

export default UKCounter;
