import { observer } from "mobx-react";
import cn from "clsx";
import UKLoadingIcon from "@/common-components-src/components/UKLoadingIcon";
import styles from "./index.module.scss";
import i18next from "i18next";

interface UKPageLoadingProps {
  loading?: boolean;
  text?: string;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const UKPageLoading: React.FC<UKPageLoadingProps> = observer((props: UKPageLoadingProps) => {
  const { loading = true, text, style, className, children } = props;
  return (
    <>
      {!loading ? (
        children
      ) : (
        <div className={cn(styles.pageLoading, className)} style={style}>
          <div className={styles.pageLoadingContent}>
            <UKLoadingIcon type="black"/>
            <div className={styles.text}>{text ? text : i18next.t ? i18next.t("common.pageStatus.loading") : ""}...</div>
          </div>
        </div>
      )}
    </>
  );
});

export default UKPageLoading;
