import { lang } from "@/common-components-src/js/server";
import _ from "lodash";

import en_US_ssr from "./locales/en_US.json";
import zh_CN_ssr from "./locales/zh_CN.json";

import en_US_csr from "../app/csr-src/i18n/locales/en_US.json";
import zh_CN_csr from "../app/csr-src/i18n/locales/zh_CN.json";

const { enUS, zhCN } = lang || {};

const en_US_lang = _.merge(enUS, en_US_ssr, en_US_csr);
const zh_CN_lang = _.merge(zhCN, zh_CN_ssr, zh_CN_csr);

export { en_US_lang, zh_CN_lang };
