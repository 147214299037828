export default {
  country: "英国",
  currency: "GBP - 英镑",
  footerCol1: "理解我们",
  footerCol2: "使用协议",
  footerCol3: "联系方式",
  FAQ: "常见问答",
  subscribeBox: {
    title: "订阅服务",
    placeholder: "填写邮箱",
    button: "提交",
    nudgeText: "订阅我们的新闻通讯，以获取特别优惠、促销活动和产品发布信息！",
  },
  downloadText: "下载Joybuy APP",
  socialMedia: "Joybuy 社交软件",
  paymentMethods: "支付方式",
  manageCookies: "管理 Cookie",
  personalisedSettings: "隐形中心",
};
