import demo from "./demo";
import address from "./address";
import header from "./header";
import common from "./common";
import footer from "./footer";

const en_US_lang = {
  address,
  header,
  demo,
  common,
  footer,
};
export default en_US_lang;
