export default {
  country: "United Kingdom",
  currency: "GBP - Pounds",
  footerCol1: "Get to know us",
  footerCol2: "Legal term & policies",
  footerCol3: "Let us help you",
  FAQ: "Frequent ask questions",
  SubscribeBox: {
    title: "Subscribe",
    placeholder: "Your email address",
    button: "Submit",
    nudgeText: "Sign up for our newsletter to receive special offers, promos and product launch information!",
  },
  downloadText: "Download Joybuy",
  socialMedia: "Find us on",
  paymentMethods: "We support",
  manageCookies: "Manage cookies",
  personalisedSettings: "Personalised settings",
};
