"use client";

import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import GiftModal from "@/common-components-src/js/gift";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import Recommend from "@/common-components-src/js/recommend/index";
import JDIHeaderNew from "@/common-components-src/js/header/index";
import JDIPrice from "@/common-components-src/js/price/index";
import JDIProductCard from "@/common-components-src/js/productCard/index";
import JDIProductCardV2 from "@/common-components-src/js/productCard_V2/index";
export { JDILogin, JDIHeaderNew, JDIPrice, JDIProductCard, callAfterLogin, Recommend, GiftModal,JDIProductCardV2, imageUtil };
