/* eslint-disable react/display-name */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "@/common-components-src/css/header.css";

import to from "await-to-js";
import cookieUtils from "@/utils/cookieUtils";
import PAGE_TYPE from "../../const/pageType";
import JDILogin from "../newlogin/index.newlogin";
import { getAddressStore } from "../address/AddressStore";
import { curPostCodeOrAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import callAfterLogin from "@/common-components-src/js/utils/callAfterLogin";
import BenefitBarWrap from "@/common-components-src/js/header/components/BenefitBarWrap";
import CategoryDropdown from "@/common-components-src/js/header/components/categoryDropdown/categoryDropdown";
import JDISearch from "@/common-components-src/js/search";
import { observer } from "mobx-react";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import { getCart, getPageAllData } from "@/common-components-src/api/header/index.api";
import { getLoginInfoApi } from "@/common-components-src/api/login/index.api";
import { DialogContent, IconButton, Avatar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UKDialog from "@/common-components-src/components/UKDialog";
import AddressPostCodeList from "@/common-components-src/js/address/addressPostList";
import LogoSVG from "@/common-components-src/assets/icons/newLogoRed.svg";
import ArrowDown from "@/common-components-src/assets/icons/arrow-down.svg";
import LangSVG from "@/common-components-src/assets/icons/lang-white.svg";
import DeliverSVG from "@/common-components-src/assets/icons/deliver.svg";

import { EXPOSURE_RECORD, sendClickTrack } from "@/tracks/25061";
import { EXPCONFIG_FAST } from "@/common-components-src/const/point";
import { LANGUAGE_CONFIG, LANGUAGE_PROPS } from "@/constants/languageConfig";

const colorImageUtil = imageUtil;

const JDIHeaderNew = observer(
  forwardRef(({ pageType, userInfo = null }, ref) => {
    const [cartNum, setCartNum] = useState(0);
    const [navItems, setNavItems] = useState<{ link: string; name: string }[]>([]);
    const [keyword, setKeyword] = useState("");
    const [myUserInfo, setMyUserInfo] = useState(userInfo); // 存储用户信息
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [curLangConfig, setCurLangConfig] = useState<LANGUAGE_PROPS>(LANGUAGE_CONFIG && LANGUAGE_CONFIG[0]);

    const minCategoryItemCount = 6;
    const addressStore = getAddressStore();
    const postCode = addressStore.postCode.get();

    useImperativeHandle(ref, () => ({
      initUserInfo,
      initCartNum,
      initSearchKeyword,
    }));

    useEffect(() => {
      // root.style.minWidth = '1264px';
      initData();
    }, []);

    useEffect(() => {
      initPostCode();
    }, []);

    const initPostCode = async () => {
      const postCode = await curPostCodeOrAddress();
      const detailPostCode = await curPostCodeOrAddress(true);
      addressStore.setPostCode(postCode);
      addressStore.setDetailPostCode(detailPostCode);
    };

    const initData = () => {
      // initStyleByPageType();
      initSearchKeyword();
      initUserInfo();
      initCartNum();
      fetchNavData();
    };

    const initSearchKeyword = () => {
      const urlParams = new URLSearchParams(window.location.search);
      setKeyword(urlParams.get("k") || "");
    };

    // const initStyleByPageType = () => {
    //   const $mainContainer = document.getElementById("jdi-header-main-container");
    //   const $mainNavWrap = document.getElementById("jdi-header-main-nav-wrap");
    //
    //   console.log("zsf this.pageType", pageType);
    //
    //   switch (pageType) {
    //     case PAGE_TYPE.INDEX:
    //     case PAGE_TYPE.NORMAL:
    //       $mainNavWrap.style.display = "block";
    //       $mainContainer.style.display = "block";
    //       break;
    //     case PAGE_TYPE.ONLY_LOGO:
    //       $mainNavWrap.style.display = "none";
    //       $mainContainer.style.display = "none";
    //       break;
    //     default: // 包含PAGE_TYPE.HIDE_CATEGORY
    //       $mainNavWrap.style.display = "none";
    //       $mainContainer.style.display = "block";
    //   }
    // };

    const initUserInfo = async () => {
      const mfs_session = cookieUtils.getCookie("mfs_session");
      setMyUserInfo({ pin: mfs_session });

      const [err, res] = await getLoginInfoApi({ types: [6] }, { meta: { forceIgnoreLogin: true } });

      if (err) {
        console.error("User info not found in response", err);
        return;
      }

      if (res?.success && res.data) {
        setMyUserInfo(res.data);
      } else {
        setMyUserInfo(null);
      }
    };

    const initCartNum = async () => {
      const [err, res] = await to(
        getCart({
          verticalTag: "cn_ybxt_b2c",
          omittedDetail: true,
          cartuuid: cookieUtils.getCookie("VISITOR_UUID"),
          userActionId: "getCart",
        }),
      );

      if (err) {
        console.error("request se_getCart error:", err);
        return;
      }
      if (res?.success) {
        let productNum = Number(res.data?.data?.subtotal?.info?.["HJM-D#subtotal&number"]?.productNum || "");
        setCartNum(productNum);
      }
    };

    const fetchNavData = async () => {
      const [err, res] = await to(
        getPageAllData({
          pageType: 5,
          systemType: 1,
          verticalTag: "cn_ybxt_b2c",
          tenantCode: "__default__",
          terminal: 2,
          buld: "405",
          businessType: 2,
          pageCode: "uk_pc_header_nav",
          businessTag: "cn_ybxt_b2c",
        }),
      );

      if (err) {
        console.error("Request error:", err);
        return;
      }

      if (!res.success) {
        return;
      }

      const data = res.data;
      const navData = data?.floors[0]?.moduleInstances?.[0]?.dataBatch?.nav;

      if (!navData || !navData.length) {
        return;
      }

      const items = navData
        .map((item) => {
          const link = item.url.jumpType === "custom" ? item.url.jumpValue.link : "";
          if (item.name && link) {
            return { name: item.name, link: link };
          }
          return null;
        })
        .filter(Boolean);

      setNavItems(items);
    };

    const handleLogin = () => {
      JDILogin.getInstance().openLoginDialog();
      sendClickTrack.Header_Singin();
    };

    const handleSignOut = () => {
      cookieUtils.clearSessionStorage("isPrivacyPolicyUpdate");
      JDILogin.getInstance().logOut();
    };

    const handlerDeliver = () => {
      setIsOpenDialog(true);
    };

    const handlerAddress = () => {
      callAfterLogin(() => {
        location.href = "/profile/my-address";
      });
    };

    const goProfileFn = () => {
      location.href = "/profile";
    };

    const finalUserInfo = myUserInfo || userInfo;

    const headerClass = pageType === PAGE_TYPE.HOME ? "jdi-header home" : "jdi-header";
    const avatarFn = (v) =>
      v ? (v.startsWith("http://") || v.startsWith("https://") || v.startsWith("//") ? v : colorImageUtil.concatImgUrlWithoutForceHttps(v)) : "";

    const closeHandler = () => {
      setIsOpenDialog(false);
    };

    const handleLanguageChange = (config: LANGUAGE_PORPS) => {
      if (config && config.type === curLangConfig.type) {
        return;
      }
      setCurLangConfig(config);
      // todo change language
      console.log("language config: ", config);
    };

    const addressDialog = () => {
      return (
        <>
          <UKDialog
            open={isOpenDialog}
            onClose={closeHandler}
            showCloseBtn={true}
            sx={{
              "& .MuiDialog-paper": {
                width: "880px", // 设置自定义宽度
                maxWidth: "none", // 取消最大宽度限制
                maxHeight: "534px",
                minHeight: "334px",
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={closeHandler}
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <AddressPostCodeList handleAddressClose={closeHandler} isOpenDialog={isOpenDialog} />
            </DialogContent>
          </UKDialog>
        </>
      );
    };

    const unsubscribeTag = () => {
      if (pageType === PAGE_TYPE.UNSUBSCRIBE) {
        return <div className="unsubscribe-txt">Unsubscribed</div>;
      }
    };

    const getMainContainerStyle = () => {
      switch (pageType) {
        case PAGE_TYPE.ONLY_LOGO:
        case PAGE_TYPE.UNSUBSCRIBE:
          return { display: "none" };
        default:
          return {};
      }
    };

    return (
      <div id="jdi-header" className={headerClass}>
        <BenefitBarWrap />
        <div className="header">
          <div className="header-main-wrap">
            <div className="w">
              <div className="header-main">
                <a
                  className="logo"
                  href="/"
                  target="_self"
                  exptag={"exp|" + EXPOSURE_RECORD.Header_Logo_Expo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                  onClick={() => {
                    sendClickTrack.Header_Logo();
                  }}
                >
                  {pageType === PAGE_TYPE.HOME ? <LogoSVG color="#FFF" width="110px" height="30px" /> : <LogoSVG color="#cc0c1c" width="110px" height="30px" />}
                </a>
                <div id="jdi-header-main-container" style={getMainContainerStyle()}>
                  <div className="main-title" id="jdi-header-main-title"></div>
                  <div className="category display-flex">
                    <div className="category-btn display-flex">
                      <svg className="category-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" fill="transparent" />
                        <path d="M3 5H17" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                        <path d="M3 10H17" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                        <path d="M3 15H17" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
                      </svg>
                      <span className="title">Categories</span>
                      <ArrowDown className="category-arrow" />
                      <CategoryDropdown />
                    </div>
                  </div>

                  <JDISearch defaultKeyword={keyword} />

                  <div
                    className="deliver display-flex"
                    exptag={"exp|" + EXPOSURE_RECORD.Header_Location_Expo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                    onClick={() => {
                      sendClickTrack.Header_Location();
                    }}
                  >
                    <button onClick={handlerDeliver} className="deliver-box display-flex" style={{ visibility: postCode ? "visible" : "hidden" }}>
                      <DeliverSVG className="deliver-icon" />
                      {postCode}
                      <ArrowDown className="deliver-arrow" />
                    </button>
                  </div>

                  <div className="country-wrap display-flex">
                    <button
                      className="country-current display-flex"
                      exptag={"exp|" + EXPOSURE_RECORD.Header_Country_Expo}
                      data-exptag-config={EXPCONFIG_FAST}
                      onClick={sendClickTrack.Header_Country}
                    >
                      <LangSVG />
                      <span className="country">{curLangConfig.displayName}</span>
                      {/* <ArrowDown className="arrow-down" /> */}
                    </button>
                    <div className="country-list-dropdown-layer">
                      <ul className="country-list">
                        {LANGUAGE_CONFIG &&
                          LANGUAGE_CONFIG.map((config, index) => (
                            <li key={index} className="country-list-item">
                              <button onClick={() => handleLanguageChange(config)}>{config.displayName}</button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  <div className="info-box top-btn-wrap margin-right">
                    {finalUserInfo?.pin ? (
                      <div
                        className="me-btn-wrap"
                        exptag={"exp|" + EXPOSURE_RECORD.Header_Me_Expo}
                        data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                        onClick={() => {
                          sendClickTrack.Header_Me();
                        }}
                      >
                        <div className="me-btn">
                          <svg className="me-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill="currentColor"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.75057 8.00305C6.75057 5.10356 9.10107 2.75305 12.0006 2.75305C14.9001 2.75305 17.2506 5.10356 17.2506 8.00305C17.2506 10.9025 14.9001 13.2531 12.0006 13.2531C9.10107 13.2531 6.75057 10.9025 6.75057 8.00305ZM12.0006 1.25305C8.27265 1.25305 5.25057 4.27513 5.25057 8.00305C5.25057 11.731 8.27265 14.7531 12.0006 14.7531C15.7285 14.7531 18.7506 11.731 18.7506 8.00305C18.7506 4.27513 15.7285 1.25305 12.0006 1.25305ZM3.52046 22.304C4.94853 19.0355 8.20919 16.7537 12.0011 16.7537C15.793 16.7537 19.0537 19.0355 20.4818 22.304C20.6476 22.6835 21.0897 22.8568 21.4693 22.6909C21.8489 22.5251 22.0221 22.083 21.8563 21.7034C20.1981 17.9083 16.4105 15.2537 12.0011 15.2537C7.59176 15.2537 3.80409 17.9083 2.14593 21.7034C1.98009 22.083 2.15335 22.5251 2.53292 22.6909C2.91248 22.8568 3.35462 22.6835 3.52046 22.304Z"
                            />
                          </svg>
                          <span className="txt">Me</span>
                        </div>
                        <div className="me-list-dropdown-layer">
                          <ul className="me-list">
                            <li className="me-list-item">
                              <Avatar
                                src={avatarFn(finalUserInfo.avatar)}
                                className="me-avatar"
                                alt="User Avatar"
                                sx={{
                                  bgcolor: "#E1E3E6",
                                  "& .MuiSvgIcon-root": {
                                    width: "100%",
                                    height: "100%",
                                    fill: "#F0F1F2",
                                  },
                                }}
                                onClick={goProfileFn}
                              />
                              {/* <img className="me-avatar" src="https://st.joy-sourcing.com/website/home/uh.png" alt="User Avatar" /> */}
                              <span style={{ marginLeft: "6px" }} onClick={goProfileFn} className="me-nickname">
                                {finalUserInfo.nickName}
                              </span>
                            </li>
                            <li className="me-list-item">
                              <a href="/profile/my-reviews">My Reviews</a>
                            </li>
                            <li className="me-list-item">
                              <a href="/profile/wishlist">Wishlist</a>
                            </li>
                            <li className="me-list-item" onClick={handlerAddress}>
                              <a href="/profile/my-address">Addresses</a>
                            </li>
                            <li className="me-list-item">
                              <a href="/profile/shopping-activity">Browsing History</a>
                            </li>
                            <li className="me-list-item">
                              <a href="#" className="sign-out" onClick={handleSignOut}>
                                Sign out
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="unlogin-btn"
                        exptag={"exp|" + EXPOSURE_RECORD.Header_Singin_Expo}
                        data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                      >
                        <button onClick={handleLogin}>
                          <svg className="me-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill="currentColor"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M6.75057 8.00305C6.75057 5.10356 9.10107 2.75305 12.0006 2.75305C14.9001 2.75305 17.2506 5.10356 17.2506 8.00305C17.2506 10.9025 14.9001 13.2531 12.0006 13.2531C9.10107 13.2531 6.75057 10.9025 6.75057 8.00305ZM12.0006 1.25305C8.27265 1.25305 5.25057 4.27513 5.25057 8.00305C5.25057 11.731 8.27265 14.7531 12.0006 14.7531C15.7285 14.7531 18.7506 11.731 18.7506 8.00305C18.7506 4.27513 15.7285 1.25305 12.0006 1.25305ZM3.52046 22.304C4.94853 19.0355 8.20919 16.7537 12.0011 16.7537C15.793 16.7537 19.0537 19.0355 20.4818 22.304C20.6476 22.6835 21.0897 22.8568 21.4693 22.6909C21.8489 22.5251 22.0221 22.083 21.8563 21.7034C20.1981 17.9083 16.4105 15.2537 12.0011 15.2537C7.59176 15.2537 3.80409 17.9083 2.14593 21.7034C1.98009 22.083 2.15335 22.5251 2.53292 22.6909C2.91248 22.8568 3.35462 22.6835 3.52046 22.304Z"
                            />
                          </svg>
                          <span className="txt">Sign in</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div
                    className="cart-wrap top-btn-wrap"
                    exptag={"exp|" + EXPOSURE_RECORD.Header_Cart_Expo}
                    data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                    data-exptag-json-param={JSON.stringify({ name: "cart" })}
                    onClick={() => {
                      sendClickTrack.Header_Cart();
                    }}
                  >
                    <a href="/cart" target="_self">
                      <svg className="top-btn-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill="currentColor"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1 2.25C0.585786 2.25 0.25 2.58579 0.25 3C0.25 3.41421 0.585786 3.75 1 3.75H3.43166C3.54678 3.75 3.64702 3.82862 3.67445 3.94042L4.51812 7.37873L6.73587 16.417C6.92791 17.1997 7.62959 17.75 8.43545 17.75H19.5343C20.3215 17.75 21.0118 17.2244 21.2212 16.4655L23.5483 8.03251C23.768 7.23649 23.1691 6.45 22.3433 6.45H5.83473L5.13124 3.58297C4.9392 2.80032 4.23752 2.25 3.43166 2.25H1ZM8.19266 16.0596L6.20279 7.95H22.015L19.7752 16.0665C19.7453 16.1749 19.6467 16.25 19.5343 16.25H8.43545C8.32033 16.25 8.22009 16.1714 8.19266 16.0596ZM8.5 22C9.32843 22 10 21.3284 10 20.5C10 19.6716 9.32843 19 8.5 19C7.67157 19 7 19.6716 7 20.5C7 21.3284 7.67157 22 8.5 22ZM18.5 22C19.3284 22 20 21.3284 20 20.5C20 19.6716 19.3284 19 18.5 19C17.6716 19 17 19.6716 17 20.5C17 21.3284 17.6716 22 18.5 22Z"
                        />
                      </svg>
                      <span>My Cart</span>
                      {cartNum ? (
                        <span id="jdi-header-cart-num" className="num">
                          {cartNum > 99 ? "99+" : cartNum}
                        </span>
                      ) : null}
                    </a>
                  </div>
                </div>
                {unsubscribeTag()}
              </div>
            </div>
          </div>

          {navItems && navItems.length >= minCategoryItemCount && (
            <div
              className="header-main-nav-wrap"
              id="jdi-header-main-nav-wrap"
              style={pageType === PAGE_TYPE.HOME || pageType === PAGE_TYPE.NORMAL ? {} : { display: "none" }}
            >
              <div className="w">
                <div className="header-main-nav">
                  <div className="nav-list-wrap">
                    <ul className="nav-list">
                      {navItems.map((item, index) => (
                        <li
                          key={index}
                          className="nav-list-item"
                          exptag={"exp|" + EXPOSURE_RECORD.Header_Navigation_Expo}
                          data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                          data-exptag-json-param={JSON.stringify({ name: item.name })}
                          onClick={() => {
                            sendClickTrack.Header_Navigation({ name: item.name || "-100" });
                          }}
                        >
                          <a href={item.link} target="_blank" rel="noopener noreferrer">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {addressDialog()}
      </div>
    );
  }),
);

export default JDIHeaderNew;
